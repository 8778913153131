import React, { useEffect, useState ,useContext} from 'react';
import '../App.css'
import AuthContext from '../store/authContext';
import 'flowbite/dist/flowbite.min.css';
import { useLocation,useNavigate } from 'react-router-dom';
import {message} from 'antd';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import emailjs from '@emailjs/browser';
import Carousel from '../component/carousel/carousel2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBed,faShower,faEye,faLocationDot, faRuler} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import RentModal from '../component/appointment/rentModal';

const PropertyDetailsPage = (props) => {
    // const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [property, setProperty] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const propertyId = queryParams.get('propertyId');

    const [startDate,setStartDate]=useState();
    const [endDate,setEndDate]=useState();

    const [propertyPrice,setPropertyPrice]=useState();
    const [totalPrice,setTotalPrice]=useState();
    const [isModalOpen, setModalOpen] = useState(false);
    const [startDates, setStartDates]=useState([]);
    const [endDates, setEndDates]=useState([]);
    const [t,i18n]= useTranslation("global");
    const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  
    
    const openModal = () => {

        setModalOpen(true);
    }
    const closeModal = () => setModalOpen(false);
    const handleFormSubmit = (e) => {
        e.preventDefault(); 
        if (!startDate|| !endDate){
            
          const dateError = t("message.dateErr"); // Store translated string in a variable
   
          message.error(dateError); 
        }
        else if(startDate>=endDate || Date.now()>startDate)  {
         
           
            message.error(t("message.dateErr"));
        }else{
            const start = new Date(startDate);
  const end = new Date(endDate);

  const oneDay = 24 * 60 * 60 * 1000; 
  const numberOfNights = Math.round((end - start) / oneDay);
          if(property.discount){
            setTotalPrice((numberOfNights+1) * property.discount);
     
          }
          else{
      setTotalPrice((numberOfNights+1) * propertyPrice);
          }
  
    openModal(); 
        }
    };
    const bookApp = async()=>{
 
        
        
        
            let status; 
            let bookedBy;
       
            if (!sessionStorage.getItem('token')) {
                localStorage.setItem('redirectAfterLogin', window.location.pathname+window.location.search);
                navigate('/logIn');
                return;
            }else if (sessionStorage.getItem('role')=== 'admin'){
                status="accepted";
             
            }else{
                status="pending";
            }
            
            
            
            const userId=sessionStorage.getItem('userId');
          
            const appData={
                userId:userId,
                startDate:startDate.toISOString(),
                endDate:endDate.toISOString(),
                propertyId:property._id,
                status:status ,
                bookedBy:sessionStorage.getItem('role'),
            }
           
         
     
 
            try{
                
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/appointment/bookApp`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(appData)
                });
                if(!response.ok){
                    console.log("error in response");
                }
                const resposeData= await response.json();
            
                if(resposeData.app.mesg==="appointment booked successfully"){
                    message.success(t("message.appSucess"));
                    setTimeout(()=>{
                        sendEmail();
                        navigate("/");
                    },2000);
                }
                else if (resposeData.app.mesg==="This property is already booked for the selected dates")
                    message.error(t("message.propertyExist"));
            }catch(err){
                console.log("error in adding appointment : "+err)
            }
    }
    const sendEmail = async()=>{
        try{
            const baseUrl = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${baseUrl}/getUserData?userId=${sessionStorage.getItem('userId')}`);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json(); 
            const email = data.userData.email;
            const name = data.userData.name;
            let propertyPrice ;
            if(property.discount){
              propertyPrice=property.discount;
            }
            else {
              propertyPrice=property.price;
            }
        

            emailjs
            .send(
              'service_jvmwwqj', // paste your ServiceID here (you'll get one when your service is created).
              'template_47yr1q6', // paste your TemplateID here (you'll find it under email templates).
              {
                toName: name,// put your name here.
                to_email: email,
                propertyName:property.propertyName,
                Location:property.location,
                startDate:startDate,
                endDate:endDate,
                pricePerNight:propertyPrice,
                totalPrice:totalPrice,
             
          
              },
              '7CHJ5K3dA2WKCthuj' //paste your Public Key here. You'll get it in your profile section.
            )
        }catch(err){

        }
       
        
    
    }
    
    useEffect(()=>{
       window.scrollTo(0, 0);
        const fetchPropertyData=async ()=>{
            try{
            
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/property/getPropertyUsingId?propertyId=${propertyId}`);
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json(); 
                
                setProperty(data.property);
              

            }catch(err){
                console.log("error in fetching property data : "+err);
            }
        }
        const fetchDisableDates= async ()=>{
            try{
             
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/appointment/getDisableDates?appId=${propertyId}`);
            
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();

                setStartDates(responseData.disabledDates.startDates);  
                setEndDates(responseData.disabledDates.endDates);
            }catch(err){
            console.log("error in fetching disable dates : "+err );
            }
        }
        
        const fetchPropertyPrice = async()=>{
            try{
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await fetch(`${baseUrl}/property/getPropPrice?propertyId=${propertyId}`);
                const responseData = await response.json();
             
                if(response){
                    setPropertyPrice(responseData.propertyPrice);
                }
            }catch(err){
                console.log("error in fetching property price "+err );
            }
        }
        fetchPropertyData();
        fetchPropertyPrice();
        fetchDisableDates();
    },[]);

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { 
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
      });
  };
  
  
  


     const isDateDisabled = (date) => {

  const inputDate = new Date(date);


  for (let i = 0; i < startDates.length; i++) {
  
    const startDate = new Date(startDates[i]);
    const endDate = new Date(endDates[i]);

   
    // const startDateMinusOneDay = new Date(startDate);
    // startDateMinusOneDay.setDate(startDateMinusOneDay.getDate() - 1);

    const endDatePlusOneDay = new Date(endDate);
    endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);

   
    if (inputDate >= startDate && inputDate <= endDatePlusOneDay) {
      return true;
    }
  }
  
  return false;
};
const dayClassName = date => {
    return isDateDisabled(date) ? 'react-datepicker__day--disabled' : undefined;
  };
      
  const displayDescription = () => {
    const { description, descriptionAR, descriptionGER } = property;
    
    // Check if all descriptions are empty
    const emptyDescriptions = !descriptionAR && !descriptionGER;
  
    if (emptyDescriptions) {
      return i18n.language === 'en' ? description : description; // English by default
    }
  
    switch (i18n.language) {
      case 'ar':
        return descriptionAR || description; // Fallback to English if Arabic is empty
      case 'de':
        return descriptionGER || description; // Fallback to English if German is empty
      default:
        return description; // Default to English
    }
  };
      

  return (
  <>
    <section className="py-5 pageDetailsCustumMargin">
      <div className="container px-4 px-lg-5 my-5">
        <div className="row gx-4 gx-lg-5 align-items-center">
          <div className="col-md-6">
            <Carousel imgUrls={property.imagesUrl} />
          </div>
          <div className="col-md-6">
            <h1 className="display-5 fw-bolder mb-3 text-center">{property.propertyName}</h1>
            <div className="flex flex-wrap justify-center">
            <span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
  <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faBed} color="orange" /> {property.numBeds}
</span>
<span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
                <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faShower} color="orange" /> {property.numShower}
              </span>
              <span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
                <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLocationDot} color="orange" /> {property.compound}
              </span>
              <span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
                <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faEye} color="orange" /> {property.view}
              </span>
              <span className="badge p-2 m-2" style={{ backgroundColor: '#59626a' }}>
                <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faRuler} color="orange" /> {property.measure} sqft
              </span>
            </div>
            <p className="lead mt-3"style={{ direction }}>
             
            {displayDescription()}
            </p>
           
            <form onSubmit={handleFormSubmit}>
              <div className="flex flex-col space-y-4 mt-3">
                <div className="flex flex-wrap justify-between">
                  <div className="flex flex-col w-full md:w-1/2">
                    <label htmlFor="dateFrom" className="text-sm font-medium mb-2">{t("from")}</label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(new Date(date.setHours(12, 0, 0, 0)))}
                      filterDate={(date) => !isDateDisabled(new Date(date.setHours(12, 0, 0, 0)))}
                      className="p-2 border rounded-md w-full"
                      placeholderText={t("arrivalDate")}
                    />
                  </div>

                  <div className="flex flex-col w-full md:w-1/2">
                    <label htmlFor="dateTo" className="text-sm font-medium mb-2">{t("to")}</label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(new Date(date.setHours(12, 0, 0, 0)))}
                      filterDate={(date) => !isDateDisabled(new Date(date.setHours(12, 0, 0, 0)))}
                      className="p-2 border rounded-md w-full"
                      placeholderText={t("leavingDate")}
                    />
                  </div>
                </div>
                <p className="lead mt-3" style={{ direction }}>
  <span style={{ fontWeight: 'bold', direction: 'inherit' }}>
    {t("pricePerNight")}
  </span>
  {property.discount ? (
    <div style={{ display: 'inline-flex', flexDirection: direction === 'rtl' ? 'row-reverse' : 'row', alignItems: 'center' }}>
      <span style={{ color: 'red', fontWeight: 'bold', margin: direction === 'rtl' ? '0 10px 0 0' : '0 0 0 10px' }}>
        {property.discount} EGP
      </span>
      <span style={{ textDecoration: 'line-through', margin: direction === 'rtl' ? '0 10px 0 0' : '0 0 0 10px' }}>
        {property.price} EGP
      </span>
    </div>
  ) : (
    <span>{property.price} EGP</span>
  )}
</p>


                <button className="px-6 mt-3 py-2 text-white bg-black rounded-md hover:bg-blue-600 w-full md:w-auto">
                  {t("reservBtn")}
                </button>

                <RentModal 
                  isOpen={isModalOpen} 
                  onClose={closeModal} 
                  onConfirm={bookApp} 
                  bookingDetails={{
                    startDate: formatDate(startDate),
                    endDate: formatDate(endDate),
                    pricePerNight: propertyPrice,
                    totalPrice: totalPrice,
                    discount:property.discount,
                  }} 
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </>
);

}

export default PropertyDetailsPage;
