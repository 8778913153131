import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL ,deleteObject  } from "firebase/storage";
import { storage } from "../../firebaseConfig/firebaseconfig";
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload, Button } from 'antd';
import '../../App.css';

const EditProperty = () => {

  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState(null);
  const [formValues, setFormValues] = useState({
    coverImgUrl: null,
    photos: []
  });
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const propId = queryParams.get('propId');

  useEffect(() => {
    if (!propId) {
      return;}
    const fetchProp = async () => {
      try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${baseUrl}/property/getPropertyUsingId?propertyId=${propId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        setProperty(responseData.property);
        setFormValues({
          coverImgUrl: responseData.property.coverImgUrl || null,
          photos: responseData.property.imagesUrl || []
        });
      } catch (err) {
        console.error(err);
        message.error('Failed to fetch property data');
      }
    };
    fetchProp();
  }, [propId]);

  const handleFinish = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (formValues.coverImgUrl===null){
      message.error('Please provide a valid cover image');
    }else if (formValues.photos.length===0){
      message.error('Please provide a propety images'); 
    }
    else {
    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());
    const originalCoverPhoto = property.coverImgUrl;
    const originalPhotos = property.imagesUrl; // Original photos from the server
    // const formValuesPhotosUrls=formValues.photos.map(photo => photo.url);

    const formValuesPhotosUrls = Array.isArray(formValues.photos) 
    ? formValues.photos.map(photo => photo.url) 
    : [];

    const unchangedPhotos = [];
    const addedPhotos = [];
    const removedPhotos = [];
    const uploadedPhotosUrls = [];

for (let photo of originalPhotos) {
  // Check if this original photo URL is in the newPhotos array
  if (!formValuesPhotosUrls.includes(photo) && !formValuesPhotosUrls.includes(undefined)) {

      removedPhotos.push(photo); // If not found in newPhotos, mark it for removal
  } else {
      unchangedPhotos.push(photo);
  }
}

for (let photo of formValues.photos) {
  // Check if the photo is a valid object
  if (typeof photo === 'object' && photo !== null) {
    // Check if the photo does not have a URL or the URL is a blob URL (indicating it's a new image)
    if (!photo.url || photo.url.startsWith('blob:')) {
      addedPhotos.push(photo);
    }
  }
  
}


    if(removedPhotos.length > 0) {
      deleteImages(removedPhotos);
    }
   

    let updatedCoverImgUrl;
    if (formValues.coverImgUrl && typeof formValues.coverImgUrl === 'object') {
            try{
              const coverRef = ref(storage, `coverImages/${formValues.coverImgUrl.name}`);
                  await uploadBytes(coverRef, formValues.coverImgUrl);
                  updatedCoverImgUrl = await getDownloadURL(coverRef);
            }catch(err){
              console.log("error in adding new cover image"+err);
            }

           } else {
      updatedCoverImgUrl = originalCoverPhoto;
    }

    if(addedPhotos.length > 0) {
    
      try {
        for (let photo of addedPhotos) {
          const photoRef = ref(storage, `photos/${photo.name}`);
          // await uploadBytes(photoRef, photo);
          const metadata = {
            contentType: photo.type, 
          };
          await uploadBytes(photoRef, photo, metadata);
          const photoUrl = await getDownloadURL(photoRef);
          uploadedPhotosUrls.push(photoUrl);
       
        }
      }
        catch(err){
          console.log("error uploading added photos ");
        }
      
    }


   

    const newImgUrls=  [...unchangedPhotos, ...uploadedPhotosUrls];
   

    try {
      const propertyData = {
        propId:propId,
        propertyType: values.propertyType,
        propertyName: values.propertyName,
        price: values.price,
        discount:values.discount,
        location: values.location,
        compound: values.compound,
        numBeds: values.numBeds,
        numShower: values.numShower,
        coverImgUrl: updatedCoverImgUrl,
        imagesUrl: newImgUrls,
        view: values.view,
        measure: values.measure,
        description: values.description,
        descriptionAR:values.descriptionAR,
        descriptionGER:values.descriptionGER,


      };
 
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${baseUrl}/property/updateProp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(propertyData)
      });
      const responseData = await response.json();
      if (responseData) {
        if (responseData.status) {
          message.success(responseData.mesg);
        } else {
          message.error(responseData.mesg);
          // window.location.reload();
        }
      }
    } catch (error) {
      message.error('Error in adding property');
      console.error("Error adding property:", error);
    }
  }
    setLoading(false);
  };

  const deleteImages= async (deletedPhotos)=>{
    for (let photo of deletedPhotos) {
      const photoRef = ref(storage, photo);
      await deleteObject(photoRef);
    }
  }





  const handleCoverImageChange = (file) => {
    setFormValues(prevValues => ({ ...prevValues, coverImgUrl: file }));
  };

  const handleCoverImageRemove = () => {
    setFormValues(prevValues => ({ ...prevValues, coverImgUrl: null }));
  };

  const handlePhotosChange = ({ fileList }) => {
  
    setFormValues(prevValues => ({
        ...prevValues,
        photos: fileList.map(file => file.originFileObj || file)
    }));
};

const handleRemovePhoto = (file) => {
  
    setFormValues(prevValues => ({
        ...prevValues,
        photos: prevValues.photos.filter(photo => photo.name !== file.name)
    }));
};

  


  const getFileList = (photos) => {
   
    return photos.map(photo => {
      // If the photo is an object with `url`, it's an existing photo from the server
      if (typeof photo === 'string') {
        return {
          uid: photo,
          url: photo,
          name: photo.split('/').pop(),
          status: 'done',
        };
      } else {
        // If the photo is a File object, it's a newly uploaded photo
        return {
          uid: photo.uid,
          name: photo.name,
          status: 'done',
          url: photo.url || URL.createObjectURL(photo), // Generate a URL for local preview
        };
      }
    });
  };
  
  
  const getCoverPhotoFile = (coverPhoto) => {
    if (!coverPhoto) {
      return null; // Return null if coverPhoto is not provided
    }
  
    if (typeof coverPhoto === 'string') {
      // If coverPhoto is a URL string
      return {
        uid: 'cover',
        url: coverPhoto,
        name: coverPhoto.split('/').pop(),
        status: 'done',
      };
    } else if (coverPhoto instanceof File) {
      // If coverPhoto is a File object
      return {
        uid: coverPhoto.uid || 'cover',
        name: coverPhoto.name,
        status: 'done',
        url: URL.createObjectURL(coverPhoto),
      };
    }
  
    return null; // Return null for any other cases
  };
  
  
  

  if (!property) {
    return <div>Loading...</div>;
  }

  return (
    <div className="customPadding flex flex-col items-center justify-center my-5 px-4">
      <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6">
        <h1 className="text-2xl font-bold text-center mb-4">Edit Property</h1>
        <form onSubmit={handleFinish} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Other form fields */}
            <div className="flex flex-col">
       
              <label htmlFor="propertyName" className="text-sm font-medium mb-2">Property Name</label>
              <input
                id="propertyName"
                name="propertyName"
                type="text"
                placeholder="Enter property name"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.propertyName}
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="compound" className="text-sm font-medium mb-2">Compound</label>
              <input
                id="compound"
                name="compound"
                type="text"
                placeholder="Enter compound name"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.compound}
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="location" className="text-sm font-medium mb-2">Location</label>
              <input
                id="location"
                name="location"
                type="text"
                placeholder="Enter location"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.location}
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="measure" className="text-sm font-medium mb-2">Measure</label>
              <input
                id="measure"
                name="measure"
                type="number"
                placeholder="Enter measure (e.g., sqm)"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.measure}
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="numBeds" className="text-sm font-medium mb-2">Number of Bedrooms</label>
              <input
                id="numBeds"
                name="numBeds"
                type="number"
                placeholder="Enter number of beds"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.numBeds}
                min="1"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="numShower" className="text-sm font-medium mb-2">Number of Bathrooms</label>
              <input
                id="numShower"
                name="numShower"
                type="number"
                placeholder="Enter number of showers"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.numShower}
                min="1"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="propertyType" className="text-sm font-medium mb-2">Property Type</label>
              <select
                id="propertyType"
                name="propertyType"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.propertyType}
                required
              >
                <option value="">Select property type</option>
                <option value="rent">Rent</option>
                <option value="sale">Sale</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="view" className="text-sm font-medium mb-2">View</label>
              <select
                id="view"
                name="view"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.view}
                required
              >
                <option value="">Select view</option>
                <option value="sea">Sea</option>
                <option value="garden">Garden</option>
                <option value="pool">Pool</option>
                <option value="city">City</option>
                <option value="marina">Marina</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="price" className="text-sm font-medium mb-2">Price</label>
              <input
                id="price"
                name="price"
                type="number"
                placeholder="Enter price"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.price}
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="discount" className="text-sm font-medium mb-2">Price after discount</label>
              <input
                id="discount"
                name="discount"
                type="number"
                placeholder="Enter price after discount"
                className="p-2 border border-gray-300 rounded"
                defaultValue={property.discount}

              />
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="description" className="text-sm font-medium mb-2">English Description</label>
            <textarea
              id="description"
              name="description"
              placeholder="Enter description"
              className="p-2 border border-gray-300 rounded"
              defaultValue={property.description}
             
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="descriptionAR" className="text-sm font-medium mb-2">Arabic Description</label>
            <textarea
              id="descriptionAR"
              name="descriptionAR"
              placeholder="Enter arabic description"
              className="p-2 border border-gray-300 rounded"
              defaultValue={property.descriptionAR}
            
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="descriptionGER" className="text-sm font-medium mb-2">German Description</label>
            <textarea
              id="descriptionGER"
              name="descriptionGER"
              placeholder="Enter german description"
              className="p-2 border border-gray-300 rounded"
              defaultValue={property.descriptionGER}
             
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="coverImgUrl" className="text-sm font-medium mb-2">Cover Image</label>
            <Upload
              name="coverImgUrl"
              listType="picture"
              maxCount={1}
              fileList={formValues.coverImgUrl ? [getCoverPhotoFile(formValues.coverImgUrl)] : []}
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
              beforeUpload={(file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                  message.error('You can only upload JPG/PNG file!');
                }
                return isJpgOrPng;
              }}
              customRequest={({ file, onSuccess }) => {
                handleCoverImageChange(file);
                onSuccess();
              }}
              onRemove={handleCoverImageRemove}
            >
              <Button icon={<UploadOutlined />}>Upload Cover Image</Button>
            </Upload>
          </div>

          <div className="flex flex-col">
            <label htmlFor="photos" className="text-sm font-medium mb-2">Photos</label>
            <Upload
              name="photos"
              listType="picture-card"
              multiple
              fileList={getFileList(formValues.photos)}
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
              style={{ width: '20em' }}
              beforeUpload={(file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                  message.error('You can only upload JPG/PNG files!');
                }
                return isJpgOrPng;
              }}
              onChange={handlePhotosChange}
              onRemove={handleRemovePhoto}
            >
              <Button icon={<UploadOutlined />} style={{ textAlign: 'center' }}>
                Upload Photos
              </Button>
            </Upload>
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="px-6 py-2 bg-black text-white rounded hover:bg-gray-800 transition"
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Update Property'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProperty;
