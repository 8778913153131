import React, { useState } from 'react';
import { Carousel } from 'antd';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css'; // Import Lightbox styles

const contentStyle = {
  height: '500px',  // This sets the height of each slide
  width: '100%',    // This makes each image fill the width of the slide
  textAlign: 'center',
  lineHeight: '400px',
  objectFit: 'cover', // This ensures the image covers the area without distorting its aspect ratio
  borderRadius: '30px',
  cursor: 'pointer'  // Indicates that the image is clickable
};

const Carousel3 = ({ imgUrls = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  return (
    <>
      <Carousel autoplay>
        {imgUrls.map((url, index) => (
          <div key={index}>
            <img
              src={url}
              alt={`Slide ${index + 1}`}
              style={contentStyle}
              onClick={() => handleImageClick(index)}
            />
          </div>
        ))}
      </Carousel>

      {isOpen && (
        <Lightbox
          slides={imgUrls.map((url) => ({ src: url }))}
          open={isOpen}
          index={currentIndex}
          close={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default Carousel3;
