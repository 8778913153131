import React from 'react';
import { FaFacebookF, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import Logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const [t]= useTranslation("global");
  return (
    <footer className="footer dark:bg-gray-900 py-5 border-t border-gray-200">
      <div className="container mx-auto w-full max-w-screen-xl px-4 mt-3">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-5">
          
          {/* Logo Section */}
          <div className="flex justify-center md:justify-start items-center mb-6 md:mb-0">
            <Link to="/" className="d-flex align-items-center">
              <img src={Logo} className="h-30 sm:h-28 md:h-32 lg:h-40" alt="Logo" />
            </Link>
          </div>

          {/* Email Section */}
          <div className="flex flex-col items-center md:items-start mb-6 md:mb-0">
            <h5 className="text-gray-900 uppercase dark:text-white mb-3 text-center md:text-left">{t("footer.emails")}</h5>
            <ul className="list-none p-0 m-0 text-left md:text-left space-y-2">
            <li>
                <a href="mailto:reservations@luxuryelgounarealestate.com" className="text-muted hover:text-gray-900 dark:hover:text-white block">reservations@luxuryelgounarealestate.com</a>
              </li>
              <li>
                <a href="mailto:info@luxuryelgounarealestate.com" className="text-muted hover:text-gray-900 dark:hover:text-white block">info@luxuryelgounarealestate.com</a>
              </li>
          
              <li>
                <a href="mailto:support@luxuryelgounarealestate.com" className="text-muted hover:text-gray-900 dark:hover:text-white block">support@luxuryelgounarealestate.com</a>
              </li>
            </ul>
          </div>

          {/* Phone Section */}
          <div className="flex flex-col items-center md:items-start">
            <h5 className="text-gray-900 uppercase dark:text-white mb-3 text-center md:text-left">{t("footer.callUs")}</h5>
            <ul className="list-none p-0 m-0 text-center md:text-left space-y-2">
              <li>
                <a href="tel:01023100148" className="text-muted hover:text-gray-900 dark:hover:text-white block">Phone: 01023100148</a>
              </li>
            </ul>
          </div>

        </div>

        <hr className="my-6 border-gray-200 dark:border-gray-700" />

        <div className="flex flex-col md:flex-row justify-between items-center">
          <span className="text-sm text-gray-500 dark:text-gray-400 text-center mb-4 md:mb-0">
            © 2023 <a href="/" className="text-black hover:underline">Gona Luxury™</a>.{t("footer.tradeMark")}
          </span>

          <div className="flex space-x-4 justify-center">
            <a href="https://www.instagram.com/el_gouna_luxury_rentals?igsh=NXhxbTRhN3Y1dHRr&utm_source=qr" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
              <FaFacebookF className="w-4 h-4" aria-hidden="true" />
              <span className="sr-only">Facebook</span>
            </a>
            <a href="https://www.instagram.com/el_gouna_luxury_rentals?igsh=NXhxbTRhN3Y1dHRr&utm_source=qr" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
              <FaInstagram className="w-4 h-4" aria-hidden="true" />
              <span className="sr-only">Instagram</span>
            </a>
            <a href="https://api.whatsapp.com/send?phone=201023100148" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
              <FaWhatsapp className="w-4 h-4" aria-hidden="true" />
              <span className="sr-only">WhatsApp</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
