import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import '../../App.css';
import AuthContext from '../../store/authContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { message } from 'antd';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const NavBar = () => {
  const {t, i18n } = useTranslation("global"); // Use the useTranslation hook
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Mobile menu
  const [dropdownOpen, setDropdownOpen] = useState(false); // Settings dropdown
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false); // Language dropdown
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const authContext = useContext(AuthContext);

  const handleGetStarted = () => {
    navigate('/logIn');
    setDropdownOpen(false);
    setLanguageDropdownOpen(false);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;

    if (scrollPosition > windowHeight) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);

  const toggleMenu = () => {
    setIsOpen(prev => !prev);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setDropdownOpen(false);
  
  };

  const toggleDropdown = () => {
    setDropdownOpen(prev => !prev);
    setLanguageDropdownOpen(false);
  };

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(prev => !prev);
    setDropdownOpen(false);
  };

  const handleLogout = () => {
    authContext.logout();
    message.success(t("message.logout"));
    setDropdownOpen(false);
    setLanguageDropdownOpen(false); 
    setIsOpen(false);
  };

  const handleChangePassword = () => {
    navigate('/changePass');
    setDropdownOpen(false);
    setLanguageDropdownOpen(false); 
    setIsOpen(false);
  };

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng); 
    setLanguageDropdownOpen(false); 
    setDropdownOpen(false);
    setIsOpen(false);
  };

  return (
    <nav className={`fixed w-full z-20 h-22 top-0 start-0 pl-3 pr-3 transition-colors duration-300 ${location.pathname === '/' ? (scrolled ? 'bg-white text-black' : 'md:bg-transparent sm:bg-white text-black') : 'bg-white text-black'}`}>
      <div className="flex flex-wrap items-center justify-between pt-0">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-40" alt="Logo" />
        </Link>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse items-center">
          <button 
            onClick={handleGetStarted} 
            type="button" 
            className={`text-sm px-4 py-2 rounded-lg font-medium focus:outline-none transition-colors duration-300 ${location.pathname === '/' ? (scrolled ? 'bg-black text-white' : 'bg-custom-nav-button text-white') : 'bg-black text-white'}`}>
            {t("navBar.getStarted")}
          </button>

          <div className="relative hidden md:block pr-2 pl-2" ref={dropdownRef}>
            <button onClick={toggleDropdown} className="flex items-center focus:outline-none">
              <FontAwesomeIcon 
                className={`text-center ${location.pathname === '/' ? (scrolled ? 'text-black' : 'text-white') : 'text-black'}`} 
                icon={faGear} 
                size="xl" 
              />
            </button>
          

          
          </div>

          <div className="hidden md:block">
            <button onClick={toggleLanguageDropdown} className="flex items-center pr-2 pl-5">
              <FontAwesomeIcon icon={faGlobe} size="xl" className={`text-center ${location.pathname === '/' ? (scrolled ? 'text-black' : 'text-white') : 'text-black'}`} />
            </button>
            {languageDropdownOpen && (
             <ul className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg py-1">
             <li>
               <button 
                 onClick={() => handleLanguageChange('en')} 
                 className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
               >
                 EN
               </button>
             </li>
             <li>
               <button 
                 onClick={() => handleLanguageChange('ar')} 
                 className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
               >
                 AR
               </button>
             </li>
             <li>
               <button 
                 onClick={() => handleLanguageChange('de')} 
                 className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
               >
                 DE
               </button>
             </li>
           </ul>
           
            )}
          

            {dropdownOpen && (
              <ul className="absolute p-0 right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg py-1">
                <li>
                  <button onClick={handleChangePassword} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Change Password
                  </button>
                </li>
                <li>
                  <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Logout
                  </button>
                </li>
              </ul>
            )}
          </div>

          {/* Mobile Menu Toggle Button */}
          <button onClick={toggleMenu} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-sticky" aria-expanded={isOpen}>
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
        </div>

        {/* Navigation Links and Mobile Dropdown */}
        <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isOpen ? 'block' : 'hidden'}`} id="navbar-sticky">
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-transparent">
            <li>
              <a 
                href="/" 
                onClick={closeMenu} 
                className={`block py-2 px-3 rounded md:p-0 transition-colors duration-300 ${location.pathname === '/' && !scrolled ? 'md:text-white sm:text-black' : 'text-black'} hover:bg-gray-200`} 
                aria-current="page">{t("navBar.home")}</a>
            </li>
            <li>
              <Link 
                to="/#aboutUs" 
                onClick={closeMenu} 
                className={`block py-2 px-3 rounded md:p-0 transition-colors duration-300 ${location.pathname === '/' && !scrolled ? 'md:text-white sm:text-black' : 'text-black'} hover:bg-gray-200`}
              >
               {t("navBar.about")}
              </Link>
            </li>
            <li>
              <Link 
                to="/#services" 
                onClick={closeMenu} 
                className={`block py-2 px-3 rounded md:p-0 transition-colors duration-300 ${location.pathname === '/' && !scrolled ? 'md:text-white sm:text-black' : 'text-black'} hover:bg-gray-200`}
              >
                 {t("navBar.services")}
              </Link>
            </li>
            <li>
              <Link 
                to="/#contactUs" 
                onClick={closeMenu} 
                className={`block py-2 px-3 rounded md:p-0 transition-colors duration-300 ${location.pathname === '/' && !scrolled ? 'md:text-white sm:text-black' : 'text-black'} hover:bg-gray-200`}
              >
                 {t("navBar.contact")}
              </Link>
            </li>

            {/* Settings Dropdown in Mobile View */}
            <li className="md:hidden">
              <div className="relative">
                <button onClick={toggleDropdown} className="block w-full text-left px-3 py-2 text-black hover:bg-gray-200 rounded">
                {t("navBar.settings")}
                </button>
                {dropdownOpen && (
                  <ul className="absolute left-0 mt-2 w-full bg-white border rounded-lg shadow-lg py-1 z-3">
                    <li>
                      <button onClick={handleChangePassword} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      {t("navBar.pass")}
                      </button>
                    </li>
                    <li>
                      <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      {t("navBar.logout")}
                      </button>
                    </li>
                  
              
                  </ul>
                  
                )}
              </div>
          
            </li>
            <li className="md:hidden">
            <div className="relative">
                        <button onClick={toggleLanguageDropdown} className="block w-full text-left px-3 py-2 text-black hover:bg-gray-200 rounded">
                        {t("navBar.language")}
                        </button>
                        {languageDropdownOpen && (
                          <ul className="absolute left-0 mt-2 w-full bg-white border rounded-lg shadow-lg py-1">
                            <li>
                              <button onClick={() => handleLanguageChange('en')} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                EN
                              </button>
                            </li>
                            <li>
                              <button onClick={() => handleLanguageChange('ar')} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                AR
                              </button>
                            </li>
                            <li>
                              <button onClick={() => handleLanguageChange('de')} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                DE
                              </button>
                            </li>
                          </ul>
                        )}
            </div>
            </li>
          
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
